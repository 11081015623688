.header{
    display: flex;
    justify-content: space-between;
    background-color: rgb(233, 241, 243);
}

.logo{
    width: 8rem;
    height: 8rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: black;
    padding: 2.5rem;
    
}

.nav-item:hover{
    color: rgb(45, 161, 210);
    cursor: pointer;
} 

ul a{
    text-decoration: none;
    color: inherit;
}

.nav-item {
    font-size: 1.5rem;
}

.menu-button{
    display: none;
    color: black;
    align-items: center;
    padding: 2.5rem;
    font-size: 2rem;
    cursor: pointer;
}

.mobile-menu{
    display: none;
}

.dropdown{
    position: relative;
}

.dropdown-toggle{
    background-color: rgb(83, 132, 181); /* Blue background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 8px 12px; /* Some padding */
    font-size: 20px; /* Set a font size */
    cursor: pointer;
}

.menu {
    position: absolute;
    list-style-type: none;
    margin: 1px 0;
    padding: 0;
    border: 1px solid grey;
    width: 80px;
    z-index: 1;
}

.menu > li {
    margin: 0;
    background-color: rgb(191, 216, 241);
}

.menu > li:hover {
    background-color: rgb(255, 255, 255);
}

.menu > li > button {
    width: 100%;
    height: 100%;
    text-align: left;
  
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .mobile-menu {
        display: none;
        background-color: white;
        width: 100%;
        transition: opacity 0.3s, transform 0.3s;
        opacity: 0;
        transform: translateY(-20px);
    }

    .mobile-menu.open {
        display: flex;
        opacity: 1;
        transform: translateY(0);
    }

    .mobile-items{
        list-style: none;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 1.5rem;
        color: black;
        padding: 2rem;
        width: 100%;

    }

    .nav-item:hover{
        color: rgb(45, 161, 210);
        cursor: pointer;
    } 

    .header-menu{
        display: none;
        flex-direction: column;
        width: 100%;
    }

    .mobile-menu{
        background-color: white;
        width: 100%;
    }

    .nav-item{
        width: 100%;
    }

    .menu-button{
        display: flex;
        
    }

    .mobile-language-selector{
        display: flex;
        width: 200px;
        height: 40px;
    }

    .mobile-language-selector > button{
        background-color: rgb(191, 218, 245); /* Blue background */
        border:none ; /* Remove borders */
        color: black; /* White text */
        padding: 4px 10px; /* Some padding */
        font-size: 12px; /* Set a font size */
        cursor: pointer;
    }

    .mobile-language-selector > button:hover{
        background-color: rgb(216, 216, 216); /* Blue background */
        border:none ; /* Remove borders */
        color: black; /* White text */
        padding: 4px 10px; /* Some padding */
        font-size: 12px; /* Set a font size */
        cursor: pointer;
    }

}