.Home{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    pad: 0 2rem;
}

.home-intro{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 2.5rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    padding-top: 4rem;
    text-align: center;
}

.home-sections{
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
}

.home-description{
    display: flex;
    font-size: 1.4rem;
    justify-content: center;
    color: white;
    text-align: center;
    padding-bottom: 4rem;
    padding-left: 6rem;
    padding-right: 6rem;
}

.section-categories{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;

}

.stock-categories{
    display: flex;
    gap: 1rem;
    padding: 0 1rem 1rem ;
    align-items: center;
    justify-content: center;
}

.category{
    display: flex;
    flex-direction: column;
    background-color: rgb(69, 113, 138);
    padding: 20px;
    gap: 0.5rem;
    color: white;
    justify-content: center;
    flex: 1;
    max-width: calc(25% - 20px);
    min-width: 250px;
    box-sizing: border-box;
}

.category-items{
    display: flex;
    flex-direction: column;
    background-color: rgb(0, 0, 0);
    padding: 2rem;
    gap: 1rem;
    color: white;
    text-align: center;
    justify-content: center;
    flex: 1;
    max-width: calc(20% - 20px);
    min-width: 60px;
    box-sizing: border-box;
    height: 200px;
}

.category-items i{
    font-size: 5rem;
}

.category>:nth-child(1){
    font-size: 1rem;
    font-weight: bold;
}

.category>:nth-child(2){
    font-size: 0.9rem;
    line-height: 25px;
}

.category:hover{
    background: var(--planCard);
    cursor: pointer;
}

.category-items:hover{
    background: var(--planCard);
    cursor: pointer;
}

.stock-categories{
    display: flex;
    gap: 1rem;
    
}

.category-title{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 2rem;
    color: var(--darkGrey);
    text-transform: uppercase;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}

.image-text-section{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/intro-image.png");
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.intro{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 2rem;
    justify-content: center;
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    padding-top: 1rem;
}

.category-title{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 2rem;
    justify-content: center;
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    padding-top: 1rem;
}

.offering-heading{
    text-align: center;
}

.offering-description{
    text-align: center;
}

.slide-caption{
    position: absolute;
    left: 50%;
    top: 50%;
     transform: translateX(-50%);
    padding: 10px;
    text-align: center;
    width: 80%;
    max-width: 300px;
    color: white;
    font-weight: bold;
    font-size: 2.5rem;
}

.link-category {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit the text color from parent */
    display: flex; /* Keep the flex layout */
    flex-direction: column;
    align-items: center; /* Align items vertically */
  }

  .images-section {
    margin-top: 40px;
  }
  
  .images-intro {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .vehicle-images {
    display: grid;
    margin-top: 1rem; 
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem; 
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
  }
  
  .vehicle-image {
    /* padding: 1rem; */
  }

@media only screen and (max-width: 768px) {
    .stock-categories{
        display: flex;
        flex-direction: column;
    }

    .category-items{
        text-align: center;
        justify-content: center;
        min-width: 100%;
        box-sizing: border-box;
        height: 100px;
    }
    
}
