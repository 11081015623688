.Footer-container{
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: rgb(233, 241, 243);
    bottom: 0;
    left: 0;
}

.left-footer{
    flex: 1;
    align-items: center;
    padding-top: 1.5rem;
    padding-left: 2rem;
}

.mid-footer{

    flex: 1;
}

.right-footer{
    flex: 1;
    justify-content: center;
    padding-top: 2rem;
}

.logo{
  display: flex;
  justify-content: center;
}

.footer-logo-text{
    align-items: center;
    color: black;
    padding-top: 1rem;
}

.footer-menu{
    color: black;
    list-style: none;
    padding: 2.5rem;
}

.mid-footer .footer-menu a{
    font-size: 1rem;
}

.Footer-container i{
    font-size: 1.3rem;
    color: black;
    padding-right: 1.5rem;
    vertical-align: middle;
}

.right-footer p{
    display: inline-block;
    vertical-align: middle;
    color: black;
}

.nav-item{
    display: flex;
    vertical-align: middle;
    padding: 0.4rem;
}

.copyright-footer{
    position: relative;
    width: 100%;
    background-color: rgb(45, 161, 210);
    color: white;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .Footer-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}